import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish a max of:`}</p>
    <p>{`1-Hang Power Snatch`}</p>
    <p>{`1-Hang Squat Snatch`}</p>
    <p>{`2-Overhead Squats`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`25-Calorie Assault Bike`}</p>
    <p>{`5-Full Snatch (135/95)(RX+ 155/105)`}</p>
    <p>{`20-Calorie Bike`}</p>
    <p>{`4-Full Snatch`}</p>
    <p>{`15-Calorie Bike`}</p>
    <p>{`3-Full Snatch`}</p>
    <p>{`10-Calorie Bike`}</p>
    <p>{`2-Full Snatch`}</p>
    <p>{`5-Calorie Bike`}</p>
    <p>{`1-Full Snatch`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      